import React from 'react'
import { PostClipFragment } from '../../../types/generated/PostClipFragment'
import { TopicFragment } from '../../../types/generated/TopicFragment'
import { AllSeries_seriesConnection_edges_node } from 'types/generated/AllSeries'
import { Dispatch, SetStateAction } from 'react'
import PostClip from 'components/Clips/PostClip'
import PillClip from 'components/Clips/PillClip'
import FavoriteClip from 'components/Clips/FavoriteClip'
import Heading from 'components/Heading'
import style from './index.module.css'
import cn from 'classnames'
import {
  AllTopicsQuery_allSeriesConnection_edges,
  AllTopicsQuery_allSeriesConnection_edges_node,
} from 'types/generated/AllTopicsQuery'

interface CarouselPayload {
  title?: string
  amount?: number
  caption?: string
  data:
    | PostClipFragment[]
    | TopicFragment[]
    | AllSeries_seriesConnection_edges_node[]
    | AllTopicsQuery_allSeriesConnection_edges[]
    | AllTopicsQuery_allSeriesConnection_edges_node[]
  className?: string
  selectionDispatch?: Dispatch<SetStateAction<string>>
}

export default function CarouselZone(props: CarouselPayload) {
  const {
    amount = 3,
    title,
    caption,
    data,
    className,
    selectionDispatch: dispatch,
  } = props

  const clips = data.slice(0, amount)

  return (
    <div className={cn(style.carousel, className)}>
      <div className={style.header}>
        {title && <Heading value={title} caption={caption} />}
      </div>

      <div className={style.scroll}>
        {(clips as Array<PostClipFragment | TopicFragment>).map((clip) => {
          const { __typename: clipType, slug } = clip
          let clipComponent

          if (clipType === 'Post') {
            clipComponent = (
              <div key={slug} className={style.clip}>
                <PostClip
                  key={clip.slug}
                  clipData={clip as PostClipFragment}
                  showAuthor
                />
              </div>
            )
          } else if (clipType === 'Series') {
            clipComponent = (
              <div key={slug} className={style.clip}>
                <FavoriteClip
                  key={clip.slug}
                  clipData={clip as TopicFragment}
                />
              </div>
            )
          } else {
            clipComponent = (
              <div key={slug}>
                <PillClip
                  key={clip.slug}
                  clipData={clip as TopicFragment}
                  dispatch={dispatch}
                />
              </div>
            )
          }

          return clipComponent
        })}
      </div>
    </div>
  )
}
