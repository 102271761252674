import PostClip from '../../Clips/PostClip'
import TopicClip from '../../Clips/TopicClip'
import Heading from '../../Heading'
import { PostClipFragment } from '../../../types/generated/PostClipFragment'
import { TopicFragment } from '../../../types/generated/TopicFragment'

interface TopicPayload {
  amount?: number
  title?: string
  caption?: string
  link?: string
  data: PostClipFragment[] | TopicFragment[] | undefined
}

export default function TopicZone(props: TopicPayload) {
  const { amount = 3, title, caption, link, data } = props

  const clips = data?.slice(0, amount)

  return (
    <div className={`feed`}>
      {title && (
        <Heading value={title} caption={caption} link={link} isMinimal />
      )}
      <div>
        {(clips as Array<PostClipFragment | TopicFragment>).map((clip) => {
          const { __typename: clipType } = clip
          if (clipType === 'Post') {
            return (
              <PostClip
                key={clip.slug}
                clipData={clip as PostClipFragment}
                showAuthor
                isFeatured
              />
            )
          }
          return (
            <TopicClip
              size={[320, 179]}
              key={clip.slug}
              clipData={clip as TopicFragment}
            />
          )
        })}
      </div>
    </div>
  )
}
