import React from 'react'

function Comment({
  stroke,
  fill,
  strokeWidth,
  children,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.8 17.4h-8.4L6.6 21v-3.6H4.2A1.2 1.2 0 013 16.2v-12A1.2 1.2 0 014.2 3h15.6A1.2 1.2 0 0121 4.2v12a1.2 1.2 0 01-1.2 1.2z"
          stroke={stroke || '#e2856e'}
          strokeWidth={strokeWidth || 1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {children}
    </>
  )
}

export default Comment
