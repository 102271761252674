import style from './index.module.css'
import { TopicFragment } from 'types/generated/TopicFragment'
import Image from 'components/Image'

export default function Favorites({ clipData }: { clipData: TopicFragment }) {
  const { name: title, featureImage: src } = clipData
  return (
    <div className={style.favoriteContainer}>
      <Image
        className={style.topicIcon}
        alt={`series icon:${title}`}
        src={src ? src : ''}
        size={[60, 60]}
      />
      <span className={style.topicTitle}>{title}</span>
    </div>
  )
}
