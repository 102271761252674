import { federatedSignIn } from '../../lib/auth'
import { OAuthProvider } from '../../lib/auth/OAuth'

interface OAuthSignInButtonProps {
  provider: OAuthProvider
  redirectUrl: string
  isSignIn?: boolean
  onClick?: () => any
}

export function OAuthSignInButton({
  provider,
  redirectUrl,
  isSignIn = true,
  onClick,
}: OAuthSignInButtonProps) {
  const btnText = `Sign ${isSignIn ? 'in' : 'up'} with ${provider}`
  return (
    <button
      aria-label={btnText}
      className={'block full'}
      onClick={() => {
        onClick && onClick()
        federatedSignIn({ provider, redirectUrl })
      }}
    >
      {btnText}
    </button>
  )
}
