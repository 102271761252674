import React from 'react'
import { Prosedoc } from 'lib/prosemirror'
import { shortenToLength } from 'lib/string'
// taken from https://github.com/hubgit/react-prosemirror/blob/main/packages/transformers/html.ts
export function ProsedocRenderer({
  prosedoc,
  excerpt,
}: {
  prosedoc?: Prosedoc
  excerpt?: boolean
}) {
  if (!prosedoc) {
    return <div />
  }

  const {
    doc: { content: toplevelContents },
  } = prosedoc

  if (excerpt) {
    if (toplevelContents[0].content) {
      return <>{shortenToLength(toplevelContents[0].content[0].text, 6)}</>
    }
  }

  return (
    <div>
      {toplevelContents.map((content, contentIndex) => {
        if (content.content) {
          return (
            <p key={contentIndex}>
              {content.content.map((leafNode, leafIndex) => {
                if (leafNode.marks) {
                  const [linkMark] = leafNode.marks
                  return (
                    <a
                      target="_blank"
                      rel="noopener, noreferrer"
                      key={`${contentIndex}-${leafIndex}`}
                      href={linkMark.attrs.href}
                    >
                      {linkMark.attrs.title || leafNode.text}
                    </a>
                  )
                }
                return leafNode.text
              })}
            </p>
          )
        }
      })}
    </div>
  )
}
