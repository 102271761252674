import Image from '../../Image'
import Link from '../../Link'
import style from './index.module.css'
import cn from 'classnames'
import { TopicFragment } from '../../../types/generated/TopicFragment'

interface ClipPayload {
  clipData: TopicFragment
  isFull?: boolean
  handleClick?: any
  className?: string
  size?: any
  sizeTabletUp?: any
  sizeDesktopUp?: any
}

export default function TopicClip(props: ClipPayload) {
  const {
    clipData,
    isFull,
    handleClick,
    className,
    size,
    sizeTabletUp,
    sizeDesktopUp,
  } = props

  const { slug: topicSlug, name, featureImage } = clipData

  return (
    <Link
      page={'/[topicSlug]'}
      params={{ topicSlug }}
      className={cn(style.clip, { [style.full]: !!isFull }, className)}
      onClick={handleClick}
    >
      {featureImage && (
        <div className={style.imageHolder}>
          <Image
            className={style.image}
            src={featureImage}
            size={size}
            sizeTabletUp={sizeTabletUp}
            sizeDesktopUp={sizeDesktopUp}
            alt={name}
          />
        </div>
      )}
      <div className={style.title}>{name}</div>
    </Link>
  )
}
