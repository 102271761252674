import cn from 'classnames'
import Image from 'components/Image'
import Time from 'components/Time'
import Link from 'components/Link'
import { PostClipFragment } from 'types/generated/PostClipFragment'
import { ImageSize } from 'lib/images'
import { useCurrentUser } from 'hooks/useCurrentUser'
import style from './index.module.css'

interface ClipPayload {
  clipData: PostClipFragment
  showAuthor?: boolean
  isList?: boolean
  isFeatured?: boolean
  hideExcerpt?: boolean
}

export default function PostClip(props: ClipPayload) {
  const { user } = useCurrentUser()
  const isSubscribedUser = user?.isSubscribed

  const {
    clipData,
    showAuthor = false,
    isList,
    isFeatured,
    hideExcerpt = false,
  } = props

  const {
    path,
    clipTitle,
    publishedAt,
    featureImage,
    excerpt,
    authorsList,
    isFree,
  } = clipData

  const size = ((): ImageSize => {
    if (isFeatured) {
      return [374, 243]
    }
    return [350, 196]
  })()

  const sizeTabletUp = ((): ImageSize => {
    if (isList) {
      return [394, 295]
    }
    if (isFeatured) {
      return [984, 443]
    }
    return [296, 166]
  })()

  const sizeDesktopUp = ((): ImageSize => {
    if (isList) {
      return [400, 300]
    }
    if (isFeatured) {
      return [1250, 463]
    }
    return [296, 166]
  })()

  return (
    <Link
      href={path}
      className={cn(
        style.clip,
        { [style.list]: !!isList },
        { [style.featured]: !!isFeatured }
      )}
    >
      {featureImage && (
        <div className={style.imageHolder}>
          {!isFree && !isSubscribedUser && (
            <div className={style.premiumFlag} />
          )}
          <Image
            className={style.image}
            src={featureImage}
            size={size}
            sizeTabletUp={sizeTabletUp}
            sizeDesktopUp={sizeDesktopUp}
            alt={clipTitle}
          />
        </div>
      )}
      <div className={style.meta}>
        <div className={style.title}>{clipTitle}</div>
        {!hideExcerpt && <div className={style.excerpt}>{excerpt}</div>}
        <div>
          {showAuthor ? (
            authorsList.map(({ name }) => (
              <span key={name} className={style.author}>
                {name}
              </span>
            ))
          ) : (
            <Time className={style.date} time={publishedAt} />
          )}
        </div>
      </div>
    </Link>
  )
}

PostClip.displayName = 'PostClip'
