// import { toAPDate } from 'lib/utils/date-utils'

interface TimePayload {
  time: any
  className: any
}

export default function Time(props: TimePayload) {
  const { time, className } = props

  const date = time && new Date(time)
  const datetime = date && date.toISOString()

  return (
    <time className={className} dateTime={datetime}>
      {toAPDate(date)}
    </time>
  )
}

export function toAPDate(date: Date): string | undefined {
  if (!date) {
    return
  }

  const dateDisplay =
    date &&
    date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  const longMonths = /january|february|august|september|october|december/i
  return dateDisplay.replace(longMonths, (month) => {
    return `${month[0].toUpperCase()}${month.slice(1, 3)}.`
  })
}
