interface AvatarProps {
  gradientColorStart: string
  gradientColorEnd: string
}

export function AuthorAvatar({
  props,
  username,
  small,
}: {
  props: AvatarProps
  username: string
  small?: boolean
}) {
  const { gradientColorStart, gradientColorEnd } = props

  return (
    <svg
      width={small ? '30' : '36'}
      height={small ? '30' : '36'}
      viewBox={small ? '0 0 30 30' : '0 0 36 36'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <defs>
          <linearGradient
            id={`${username}-avatar-gradient`}
            x1="0"
            y1="0"
            x2="1"
            y2="1"
          >
            <stop offset="-15%" stopColor={gradientColorStart} />
            <stop offset="110%" stopColor={gradientColorEnd} />
          </linearGradient>
        </defs>
        <circle
          stroke={`url(#${username}-avatar-gradient)`}
          fill="hsl(210, 100%, 99%)"
          cx={small ? '15' : '18'}
          cy={small ? '15' : '18'}
          r={small ? '15' : '18'}
          strokeWidth={small ? '3' : '4'}
        />
        <text
          fontFamily="Lato, sans-serif"
          x="50%"
          y="50%"
          alignmentBaseline="central"
          dominantBaseline="central"
          textAnchor="middle"
          fill={`hsl(240, 27%, 18%)`}
          fontSize={small ? '18px' : '20px'}
        >
          {username[0].toUpperCase()}
        </text>
      </g>
    </svg>
  )
}
